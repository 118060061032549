import request, { IResponseEntity, IResponseList } from '@/api/request'
import { IStage, IStageSettings } from '@/models/entities/stage'

export const getStageSettings = params => {
  return request<IResponseList<IStageSettings>>({
    url: `/api/v1/user_development/stage_settings/list`,
    method: 'GET',
    params,
  })
}

export const getStages = params => {
  return request<IResponseList<IStage>>({
    url: `/api/v1/user_development/stages`,
    method: 'GET',
    params,
  })
}

export const getStage = id => {
  return request<IResponseEntity<IStage>>({
    url: `/api/v1/user_development/stages/${id}`,
    method: 'GET',
  })
}

export const createStage = data => {
  return request<IResponseEntity<IStage>>({
    url: `/api/v1/user_development/stages`,
    method: 'POST',
    data,
  })
}

export const editStage = (id, data) => {
  return request<IResponseEntity<IStage>>({
    url: `/api/v1/user_development/stages/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteStage = id => {
  return request({
    url: `/api/v1/user_development/stages/${id}`,
    method: 'DELETE',
  })
}

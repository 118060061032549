import request, { IResponseEntity, IResponseList } from '@/api/request'
import { IAddRetranslationPayload, IAddUseobjectLicenseData } from './models'
import {
  IUseobject,
  IUseobjectGroup,
  IUseobjectGroupType,
  IUseobjectLicense,
  IUseobjectsZoneGroupPayload,
  IUseobjectsZonePayload,
  IUseobjectZone,
  IUseobjectZoneGroup,
} from '@/models/entities/useobject'
import { IRetranslation } from '@/models/entities/retranslation'
import { IUseobjectV2 } from '@/models/entities/useobjectV2'
import { IBroadcastEnvironment } from '@/models/entities/broadcastEnvironment'

export const getUseobjectLicenses = (params?: unknown) => {
  return request<IResponseList<IUseobjectLicense>>({
    url: `/api/v1/useobject/licenses`,
    method: 'GET',
    params,
  })
}

export const addUseobjectLicense = (data?: IAddUseobjectLicenseData) => {
  return request({
    url: `/api/v1/useobject/licenses`,
    method: 'POST',
    data,
  })
}

export const deleteUseobjectLicense = (id: IUseobjectLicense['id']) => {
  return request({
    url: `/api/v1/useobject/licenses/${id}`,
    method: 'DELETE',
  })
}

export const getUseobjectGroups = (data?: unknown) => {
  return request<IResponseList<IUseobjectGroup>>({
    url: `/api/v1/useobject/external/groups`,
    method: 'GET',
    params: data,
  })
}

export const getUseobjectGroupsTypes = (data?: unknown) => {
  return request<IResponseList<IUseobjectGroupType>>({
    url: `/api/v1/useobject/group_types`,
    method: 'GET',
    params: data,
  })
}

export const createUseobjectGroup = data => {
  return request({
    url: `/api/v1/useobject/external/groups`,
    method: 'POST',
    data,
  })
}

export const getUseObjectGroupById = id => {
  return request<IUseobjectGroup>({
    url: `/api/v1/useobject/external/groups/${id}`,
    method: 'GET',
  })
}
export const deleteUseObjectGroup = id => {
  return request({
    url: `/api/v1/useobject/external/groups/${id}`,
    method: 'DELETE',
  })
}

export const updateUseObjectGroup = data => {
  return request({
    url: `/api/v1/useobject/external/groups/${data.id}`,
    method: 'PUT',
    data,
  })
}

export const getRetranslations = (params?: unknown) => {
  return request<IResponseList<IRetranslation>>({
    url: '/api/v1/useobject/retranslations',
    params,
  })
}

export const createRetranslation = (data: IAddRetranslationPayload) => {
  return request<IResponseEntity<IRetranslation>>({
    url: '/api/v1/useobject/retranslations',
    method: 'POST',
    data,
  })
}

export const updateRetranslation = (id: IRetranslation['id'], data: IAddRetranslationPayload) => {
  return request<IResponseEntity<IRetranslation>>({
    url: `/api/v1/useobject/retranslations/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteRetranslation = (id: IRetranslation['id']) => {
  return request<IResponseEntity<IRetranslation>>({
    url: `/api/v1/useobject/retranslations/${id}`,
    method: 'DELETE',
  })
}

export const getUseobjectsV2 = (params?: unknown) => {
  return request<IResponseList<IUseobjectV2>>({
    url: '/api/v2/useobject/useobjects',
    params,
  })
}

export const getBroadcastEnvironments = (params?: unknown) => {
  return request<IResponseList<IBroadcastEnvironment>>({
    url: '/api/v1/useobject/broadcast_environments',
    params,
  })
}

export const getUseobjectZones = (id: IUseobject['id'], params?: unknown) => {
  return request<IResponseList<IUseobjectZoneGroup>>({
    url: `/api/v1/useobject/zones/${id}`,
    method: 'GET',
    params,
  })
}

export const createUseobjectZoneGroup = (data: IUseobjectsZoneGroupPayload) => {
  return request<IResponseEntity<IUseobjectZoneGroup>>({
    url: '/api/v1/useobject/zone_groups',
    method: 'POST',
    data,
  })
}

export const updateUseobjectZoneGroup = (
  id: IUseobjectZoneGroup['id'],
  data: IUseobjectsZoneGroupPayload,
) => {
  return request<IResponseEntity<IUseobjectZoneGroup>>({
    url: `/api/v1/useobject/zone_groups/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteUseobjectZoneGroup = (id: IUseobjectZoneGroup['id']) => {
  return request({
    url: `/api/v1/useobject/zone_groups/${id}`,
    method: 'DELETE',
  })
}

export const createUseobjectZone = (data: IUseobjectsZonePayload) => {
  return request<IResponseEntity<IUseobjectZone>>({
    url: '/api/v1/useobjects/zone',
    method: 'POST',
    data,
  })
}

export const updateUseobjectZone = (id: IUseobjectZone['id'], data: IUseobjectsZonePayload) => {
  return request<IResponseEntity<IUseobjectZone>>({
    url: `/api/v1/useobjects/zone/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteUseobjectZone = (id: IUseobjectZone['id']) => {
  return request({
    url: `/api/v1/useobjects/zone/${id}`,
    method: 'DELETE',
  })
}

import request from '@/api/request'
import { tableConfig } from './mock'

export const getTableConfig = () => Promise.resolve(tableConfig)

export const getUsecategoriesList = data => {
  return request({
    url: `/api/v1/usecategory/usecategories`,
    method: 'GET',
    params: data,
  })
}

export const getBaseUsecategoriesList = data => {
  return request({
    url: `/api/v1/usecategory/base_usecategories`,
    method: 'GET',
    params: data,
  })
}

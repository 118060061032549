import request, { IResponseList, IResponseEntity } from '@/api/request'
import {
  IContactDictionary,
  INameComponentType,
  IPersonContact,
  IPersonContactCreate,
  IPersonContactUpdate,
} from '@/models/entities/contact'
import { IPersonDocument, IPersonDocumentCreate } from '@/models/entities/document'

export const getContactDictionaries = (data?: unknown) => {
  return request<IResponseList<IContactDictionary>>({
    url: `/api/v1/contacts/dictionaries`,
    method: 'GET',
    params: data,
  })
}

export const getNameComponentTypes = (data?: unknown) => {
  return request<IResponseList<INameComponentType>>({
    url: `/api/v1/contact/name_component_types`,
    method: 'GET',
    params: data,
  })
}

export const deleteContactDictionary = (id: string) => {
  return request({
    url: `/api/v1/contacts/dictionaries/${id}`,
    method: 'DELETE',
  })
}

export const createContactDictionary = (data = {}) => {
  return request({
    url: '/api/v1/contacts/dictionaries',
    method: 'POST',
    data,
  })
}

export const editContactDictionary = (id: string, data: unknown) => {
  return request({
    url: `/api/v1/contacts/dictionaries/${id}`,
    method: 'PUT',
    data,
  })
}

export const getContactTypes = (data?: unknown) => {
  return request<IResponseList<INameComponentType>>({
    url: `/api/contact/contact_types`,
    method: 'GET',
    params: data,
  })
}

export const createContact = (data: IPersonContactCreate) => {
  return request<IResponseEntity<IPersonContact>>({
    url: '/api/v1/contact/contacts',
    method: 'POST',
    data,
  })
}

export const editContact = (data: IPersonContactUpdate) => {
  return request<IResponseEntity<IPersonContact>>({
    url: `/api/v1/contact/contacts/${data.id}`,
    method: 'PUT',
    data,
  })
}

export const deleteContact = (id: string) => {
  return request({
    url: `/api/v1/contact/contacts/${id}`,
    method: 'DELETE',
  })
}

export const createContactDocument = (data: IPersonDocumentCreate) => {
  return request<IResponseEntity<IPersonDocument>>({
    url: '/api/v1/contact/documents',
    method: 'POST',
    data,
  })
}

export const editContactsDocument = (id: string, data: unknown) => {
  return request<IResponseEntity<IPersonDocument>>({
    url: `/api/v1/contact/documents/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteContactDocument = (id: string) => {
  return request({
    url: `/api/v1/contact/documents/${id}`,
    method: 'DELETE',
  })
}

export const getContactTypeById = (id: string | number) => {
  return request<IResponseEntity<INameComponentType>>({
    url: `/api/contact/contact_types/${id}`,
    method: 'GET',
    data: null,
  })
}

export const getPersonSexes = (data?: unknown) => {
  return request<IResponseList<INameComponentType>>({
    url: `/api/v1/contacts/sex`,
    method: 'GET',
    params: data,
    data: null,
  })
}

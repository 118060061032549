export const tableConfig = {
  columns: [
    {
      field: 'name',
      label: 'Наименование',
      preset: 'table.columns.TableColumnDefaultPreset',
      presetParams: {
        options: {
          width: '225',
        },
      },
      presence: {
        readonly: false,
        resizable: false,
      },
      filter: {
        label: 'Поиск по имени',
        preset: 'form.text.FormTextPlainPreset',
        field: 'filters.name.value',
        queryOperator: {
          field: 'filters.name.op',
          value: 'contains',
        },
        debounce: true,
      },
    },
    {
      field: 'bank',
      label: 'Банк',
      preset: 'table.columns.TableColumnBankPreset',
      presetParams: {
        options: {
          width: '225',
        },
      },
      presence: {
        readonly: false,
        resizable: false,
      },
      filter: {
        label: 'Поиск в справочнике банков',
        preset: 'form.text.FormTextPlainPreset',
        field: 'filters.bankQuery',
        queryOperator: {
          field: undefined,
          value: undefined,
        },
        debounce: true,
      },
    },
    {
      field: 'account',
      label: 'Расчетный счет',
      preset: 'table.columns.TableColumnDefaultPreset',
      presetParams: {
        options: {
          width: '150',
        },
      },
      presence: {
        readonly: false,
        resizable: false,
      },
      filter: {
        label: 'Поиск по расчетному счету',
        preset: 'form.text.FormTextPlainPreset',
        field: 'filters.account.value',
        queryOperator: {
          field: 'filters.account.op',
          value: 'contains',
        },
        debounce: true,
      },
    },
    {
      field: 'currency.code',
      label: 'Валюта',
      preset: 'table.columns.TableColumnDefaultPreset',
      presetParams: {
        options: {
          width: '70',
        },
      },
      presence: {
        readonly: false,
        resizable: true,
      },
      filter: {
        label: 'Валюта',
        preset: 'form.select.FormSelectBasicPreset',
        field: 'filters.currency.value',
        queryOperator: {
          field: 'filters.currency.op',
          value: 'eq',
        },
        presetParams: {
          optionsProvider: 'CURRENCIES',
          optionsMapKey: 'code',
          optionsMapValue: 'id',
          queryParams: [
            {
              field: 'filters.availableInReceiverAccounts.value',
              value: true,
            },
            {
              field: 'filters.availableInReceiverAccounts.op',
              value: 'eq',
            },
            {
              field: 'filters.s.field',
              value: 'id',
            },
            {
              field: 'filters.s.dir',
              value: 'asc',
            },
          ],
          options: null,
          naiveProps: {
            consistentMenuWidth: false,
            placeholder: '',
          },
          width: '60',
        },
        debounce: false,
      },
    },
    {
      field: 'description',
      label: 'Описание',
      preset: 'table.columns.TableColumnInfoTooltipPreset',
      presetParams: {
        options: {
          width: '100',
        },
      },
      presence: {
        readonly: false,
        resizable: true,
      },
    },
    {
      field: 'customData.NameAxapta',
      label: 'Счёт в Axapta',
      preset: 'table.columns.TableColumnDefaultPreset',
      presetParams: {
        options: {
          width: '150',
        },
      },
      presence: {
        readonly: false,
        resizable: true,
      },
    },
    {
      field: 'status',
      label: 'Статус',
      preset: 'table.columns.TableColumnDefaultPreset',
      presetParams: {
        options: {
          width: '110',
        },
      },
      presence: {
        readonly: false,
        resizable: true,
      },
      filter: {
        label: 'Статус',
        preset: 'form.select.FormSelectBasicPreset',
        field: 'filters.statusId.value',
        queryOperator: {
          field: 'filters.statusId.op',
          value: 'eq',
        },
        presetParams: {
          optionsProvider: 'RECEIVER_STATUSES',
          options: null,
          naiveProps: {
            consistentMenuWidth: false,
          },
          width: '120',
        },
        debounce: false,
      },
    },
    {
      field: '',
      label: '',
      preset: 'table.columns.TableColumnControlsPreset',
      presetParams: {
        options: {
          minWidth: '80px',
          rowControls: {
            isEditable: 'true',
            isDeletable: 'true', // TODO после HG3-1254 подключить вычисляемое поле refCount > 0 ? false : true
          },
        },
      },
    },
  ],
}

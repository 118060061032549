export const tableConfig = {
  columns: [
    {
      field: 'bankName',
      label: 'Наименование',
      preset: 'table.columns.TableColumnDefaultPreset',
      presetParams: {
        options: {
          width: '225',
        },
      },
      presence: {
        readonly: false,
        resizable: false,
      },
      filter: {
        label: 'Поиск по имени',
        preset: 'form.text.FormTextPlainPreset',
        field: 'filters.bankName.value',
        queryOperator: {
          field: 'filters.bankName.op',
          value: 'contains',
        },
        debounce: true,
      },
    },
    {
      field: 'bankCode',
      label: 'Код банка',
      preset: 'table.columns.TableColumnDefaultPreset',
      presetParams: {
        options: {
          width: '120',
        },
      },
      presence: {
        readonly: false,
        resizable: false,
      },
      filter: {
        label: 'Поиск по коду банка',
        preset: 'form.text.FormTextPlainPreset',
        field: 'filters.bankCode.value',
        queryOperator: {
          field: 'filters.bankCode.op',
          value: 'contains',
        },
        debounce: true,
      },
    },
    {
      field: 'corrAccount',
      label: 'Корреспондентский счет',
      preset: 'table.columns.TableColumnDefaultPreset',
      presetParams: {
        options: {
          width: '120',
        },
      },
      presence: {
        readonly: false,
        resizable: false,
      },
    },
    {
      field: 'country.name',
      label: 'Страна',
      preset: 'table.columns.TableColumnDefaultPreset',
      presetParams: {
        options: {
          width: '120',
        },
      },
      presence: {
        readonly: false,
        resizable: false,
      },
      filter: {
        label: 'Страна',
        preset: 'form.select.FormSelectBasicPreset',
        field: 'filters.countryId.value',
        queryOperator: {
          field: 'filters.countryId.op',
          value: 'eq',
        },
        presetParams: {
          optionsProvider: 'COUNTRIES',
          options: null,
          filterable: true,
        },
        width: '120',
        debounce: false,
      },
    },
    {
      field: 'status.name',
      label: 'Статус',
      preset: 'table.columns.TableColumnDefaultPreset',
      presetParams: {
        options: {
          width: '120',
        },
      },
      presence: {
        readonly: false,
        resizable: false,
      },
      filter: {
        label: 'Статус',
        preset: 'form.select.FormSelectBasicPreset',
        field: 'filters.statusId.value',
        queryOperator: {
          field: 'filters.statusId.op',
          value: 'eq',
        },
        presetParams: {
          optionsProvider: 'BANK_STATUSES',
          options: null,
          naiveProps: {
            consistentMenuWidth: false,
          },
          width: '120',
        },
        debounce: false,
      },
    },
    {
      field: '',
      label: '',
      preset: 'table.columns.TableColumnControlsPreset',
      presetParams: {
        options: {
          minWidth: '80px',
          rowControls: {
            isEditable: 'true',
            isDeletable: 'true',
          },
        },
      },
    },
  ],
}
